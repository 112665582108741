/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
html, body {
    /* disable pull-down refresh */
    overscroll-behavior: none;
}

@font-face {
  font-family: "Montserrat";
  src: url("fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("fonts/Inter-Regular.ttf");
}
